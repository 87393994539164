<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-text class="pa-5 pb-0">
            <div class="d-sm-flex align-center">
              <div>
                <h2 class="font-weight-regular title">Crear nota</h2>
              </div>
            </div>
          </v-card-text>
          <v-card-text class=" border-bottom">
            <TheNoteForm
              @formValidated="doCreateNote"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: "NewNote",
  components: {
    TheNoteForm: () =>
      import(
        "@/components/dashboardComponents/TheNoteForm"
      )
  },
  data: () => ({
    create: false,
    page: {
      title: "Notas"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Notas",
        disabled: false,
        href: "/dashboard/notes/"
      },
      {
        text: "Crear nota",
        disabled: true
      }
    ]
  }),
  async created () {
    await this.fetchTopics()
  },
  computed: {
  },
  methods: {
    ...mapActions('notes', ['fetchTopics']),
    async doCreateNote (note) {
      try {
        const payload = {
          titulo: note.titulo,
          temas: note.temas.map(item => item.id),
          resumen: note.resumen,
          cuerpo: note.cuerpo,
          pie_1: note.pie_1,
          pie_2: note.pie_2
        }
        const noteCreated = await axios.post('api/admin/notas/notas/', payload)
        const confirm = await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Nota creada.'
        })
        if (confirm) {
          await this.$router.push({ name: 'UpdateNote',  params: { id: noteCreated.data.id }})
        }
      } catch (e) {
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    }
  }
}
</script>
